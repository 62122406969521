import React from "react"
import { useInView } from 'react-intersection-observer'
import * as styles from '../../styles/embedmap.module.scss'

const EmbedMap = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <div className={styles.mapwrapper}
            ref = {ref}
        >
            {inView ? (
                <div className={styles.googlemap}>
                    {children}
                </div>            
            ) : (
                <div></div>
            )}
        </div>
    )
}

export default EmbedMap