import React from 'react'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'
import * as styles from '../../styles/twofleximg.module.scss'

const TwoFlexImg = ({limg, lalt, rimg, ralt, ispositionp, issmaller}) =>{


    return(
        <div className = {issmaller ? styles.smallerwrapper : styles.normalwrapper}>
            <div className = {ispositionp ? styles.twofleximgwrapperp : styles.twofleximgwrapper}>
                <GatsbyImage className = {styles.imagestyle}
                    alt = {lalt} 
                    image = {getImage(limg)}
                    width = {200}
                />

                <GatsbyImage className = {styles.imagestyle}
                    alt = {ralt} 
                    image = {getImage(rimg)}
                    width = {200}
                />
            </div>
        </div>
    )
}

TwoFlexImg.defaultProps = {
    ispositionp: false,
    issmaller: false
}

export default TwoFlexImg