import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import EmbedMap from "../../../../src/components/blogparts/embedmap";
import ExternalLink from "../../../../src/components/blogparts/externallink";
import SingleImg from "../../../../src/components/blogparts/singleimg";
import TwoFlexImg from "../../../../src/components/blogparts/twofleximg";
import RichInternalLink from "../../../../src/components/blogparts/richinternallink";
import * as styles from "../../../../src/styles/basemdx.module.scss";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  EmbedMap,
  ExternalLink,
  SingleImg,
  TwoFlexImg,
  RichInternalLink,
  styles,
  React
};