import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import EmbedMap from "../../../../src/components/blogparts/embedmap";
import EmbedYoutube from "../../../../src/components/blogparts/embedyoutube";
import ExternalLink from "../../../../src/components/blogparts/externallink";
import SingleImg from "../../../../src/components/blogparts/singleimg";
import TwoFlexImg from "../../../../src/components/blogparts/twofleximg";
import CaptionImg from "../../../../src/components/blogparts/captionimg";
import CaptionTwoFlexImg from "../../../../src/components/blogparts/captiontwofleximg";
import RichInternalLink from "../../../../src/components/blogparts/richinternallink";
import MoshimoAffiliate from "../../../../src/components/blogparts/moshimoaffiliate";
import DestInfo from "../../../../src/components/blogparts/destinfo";
import * as styles from "../../../../src/styles/basemdx.module.scss";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  EmbedMap,
  EmbedYoutube,
  ExternalLink,
  SingleImg,
  TwoFlexImg,
  CaptionImg,
  CaptionTwoFlexImg,
  RichInternalLink,
  MoshimoAffiliate,
  DestInfo,
  styles,
  React
};