import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import EmbedMap from "../../../../src/components/blogparts/embedmap";
import ExternalLink from "../../../../src/components/blogparts/externallink";
import * as styles from "../../../../src/styles/basemdx.module.scss";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  EmbedMap,
  ExternalLink,
  styles,
  React
};