import React from 'react'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'
import * as styles from '../../styles/captiontwofleximg.module.scss'

const CaptionTwoFlexImg = ({limg, lalt, rimg, ralt, alt, ispositionp}) =>{


    return(
        <div className={styles.captiontwofleximgwrapper}>
            <div className = {ispositionp ? styles.twofleximgwrapperp : styles.twofleximgwrapper}>
                <GatsbyImage className = {styles.imagestyle}
                    alt = {lalt} 
                    image = {getImage(limg)}
                    width = {200}
                />

                <GatsbyImage className = {styles.imagestyle}
                    alt = {ralt} 
                    image = {getImage(rimg)}
                    width = {200}
                />
            </div>

            <div className={styles.caption}>
                {alt}
            </div>

        </div>
    )
}

CaptionTwoFlexImg.defaultProps = {
    ispositionp: false
}

export default CaptionTwoFlexImg