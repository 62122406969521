import React from 'react';
import { FaInfoCircle } from "react-icons/fa";
import * as styles from '../../styles/destinfo.module.scss';

const DestInfo = ({ children }) => (
    <div className={styles.destinfowrapper}>
        <FaInfoCircle className={styles.infocircle} color={"#e09200"} size={"2rem"}/>
        {children}
    </div>
);

export default DestInfo;