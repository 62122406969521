import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import * as styles from '../../styles/embedsuzuri.module.scss'

const EmbedSuzuri = ({url1,url2}) =>{
    let firstsuzuri = []
    let secondsuzuri = []

    if(url1){
        firstsuzuri =
        <iframe 
            src={`${url1}/embed`}
        >   
        </iframe>
    }
    if(url2){
        secondsuzuri =
        <iframe 
            src={`${url2}/embed`}
        >   
        </iframe>
    }


    return (
        <div className={styles.suzuriwrapper}>
            <StaticImage className={styles.itemlogo}
                src = "../../images/item.png"
                alt='itemlogo'
                width = {100}
            />
            <div className={styles.text}>
                <p>バイカー向けの<span className={styles.boldtext}>オシャレなオリジナルグラフィックアイテム</span>も販売中！</p>
                <p>普段使いもできる、バイカーらしいアイテムです！</p>            
            </div>
            <div className={styles.suzuricontentswrapper}>
                {firstsuzuri}
                {secondsuzuri}
            </div>

        </div>
    );
}

export default EmbedSuzuri