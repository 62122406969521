import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react'
import * as styles from '../../styles/embedyoutube.module.scss'
import ChannelSubscribeLink from './channelsubscribe';

const EmbedYoutube = ({id}) =>{

    const [isThumbnail, setIsThumbnail] = useState(true);

    return (
        <div className={styles.youtubewrapper}>
            {isThumbnail ? (
                <div className = {styles.thumbnailwrapper}>
                    <img className={styles.youtube}
                        src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`}
                        onClick={() => setIsThumbnail(false)}
                        alt="サムネイル"
                        loading='lazy'
                    />
                    <StaticImage className={styles.youtubelogo}
                        src = "../../images/youtube-red-logo.png"
                        alt='youtubelogo'
                        width = {100}
                    />
                </div>
            ) : (
            <div className = {styles.thumbnailwrapper}>
                <iframe className={styles.youtube}
                    src={`https://www.youtube.com/embed/${id}?autoplay=1`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            )}
            <ChannelSubscribeLink/>
        </div>
    );
}


export default EmbedYoutube