import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from '../../styles/linestamp.module.scss';

const LineAdsLink = () => {


    return(
        <div className = {styles.lineadswrapper}>
            <div className={styles.leadtext}>
                LINEスタンプも販売中！チェックしてみてね！
            </div>
            <div className={styles.flexwrapper}>
                <a href = "https://line.me/S/shop/sticker/author/3245917" target = "_blank" rel="noopener noreferrer">
                    <div className = {styles.stamplinkwrapper}>
                        <StaticImage className={styles.stamplinkimg} src = "../../images/linestamp2.png"
                        />
                    </div>
                </a>
                <a href = "https://lin.ee/la5qfip" target = "_blank" rel="noopener noreferrer">
                    <div className = {styles.otomodachilinkwrapper}>
                        <StaticImage src = "../../images/otomodachi.png"
                        />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default LineAdsLink;