export const createCategoryNameMap = () =>{
    const categorynamemap = {
        Oita: "大分の紹介",
        OitaCity: "大分",
        BeppuCity: "別府",
        NakatsuUsaCity: "中津・宇佐",
        BungotakadaKunisakiCity: "豊後高田・国東",
        KitsukiHijiCity:"杵築・日出",
        HitaCity: "日田",
        YufuCity:"湯布院",
        KusuKokonoeTown:"玖珠・九重",
        TaketaBungooonoCity:"竹田・豊後大野",
        UsukiTukumiCity:"臼杵・津久見",
        SaikiCity:"佐伯",
        Others:"ツーリング以外のネタ",
        Fukuoka:"福岡",
        FukuokaCity:"福岡市",
        Miyazaki:"宮崎",
        MiyazakiCity:"宮崎市",
        Kumamoto:"熊本",
        AsoGun:"阿蘇郡",
        Gear:"使っているもの",
    }
    return(
        categorynamemap
    )
}

export const createCategorySlugMap = () =>{
    const categoryslugmap = {
        OitaCity: "/oita/oita-city",
        BeppuCity: "/oita/beppu-city",
        NakatsuUsaCity: "/oita/nakatsu-usa-city",
        BungotakadaKunisakiCity: "/oita/bungotakada-kunisaki-city",
        KitsukiHijiCity:"/oita/kitsuki-hiji-city",
        HitaCity: "/oita/hita-city",
        YufuCity:"/oita/yufu-city",
        KusuKokonoeTown:"/oita/kusu-kokonoe-town",
        TaketaBungooonoCity:"/oita/taketa-bungooono-city",
        UsukiTukumiCity:"/oita/usuki-tukumi-city",
        SaikiCity:"/oita/saiki-city",
        FukuokaCity:"/fukuoka/fukuoka-city",
        MiyazakiCity:"/miyazaki/miyazaki-city",
        AsoGun:"/kumamoto/aso-gun",
        Gear:"/others/gear",
    }
    return(
        categoryslugmap
    )
}

export const createCategory2Slug = () =>{
    const categoryslugmap = 
        [["oita","fukuoka","kumamoto"],
        ["oita-city",
        "beppu-city",
        "nakatsu-usa-city",
        "bungotakada-kunisaki-city",
        "kitsuki-hiji-city",
        "hita-city",
        "yufu-city",
        "kusu-kokonoe-town",
        "taketa-bungooono-city",
        "usuki-tukumi-city",
        "saiki-city",
        "fukuoka-city",
        "miyazaki-city",
        "aso-gun",
        "gear",
        ]]
    
    return(
        categoryslugmap
    )
}

export const createTagsNameMap = () =>{
    const tagsnamemap = {
        cafe:"カフェ",
        karaage: "からあげ",
        photo: "写真",
        onsen: "温泉",
        michinoeki: "道の駅",
        kouen: "公園",
        kanko: "観光",
        camp: "キャンプ",
        umi: "海",
        yama: "山",
        omiyage: "お土産",
        hana: "花",
        review: "レビュー",
        camera: "カメラ",
        gourmet: "グルメ",
    }
    return(
        tagsnamemap
    )
}

export const createLinkStyle = () =>{
    const linkstyle = {
        color:"#242424",
        textDecoration:"none",
    }
    return(
        linkstyle
    )
}