// extracted by mini-css-extract-plugin
export var badlist = "basemdx-module--badlist--LmuMG";
export var destinfowrapper = "basemdx-module--destinfowrapper--baPqc";
export var goodlist = "basemdx-module--goodlist--MO89k";
export var imagestyle = "basemdx-module--imagestyle--KrhZq";
export var infowrapper = "basemdx-module--infowrapper--R+ayS";
export var middletable = "basemdx-module--middletable--XPZeG";
export var olstyle = "basemdx-module--olstyle--HC3BV";
export var tablestyle = "basemdx-module--tablestyle--4rjqj";
export var title = "basemdx-module--title--KbY3C";
export var ulstyle = "basemdx-module--ulstyle--B+B19";