import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from '../../styles/linestamp.module.scss';

const ChannelSubscribeLink = () => {

    return(
        <div className = {styles.linestampwrapper}>
            <a href = "http://www.youtube.com/channel/UCgWmX8O5qubjBKTZZ3KvX6Q?sub_confirmation=1" target = "_blank" rel="noopener noreferrer">
                <div className = {styles.subscribewrapper}>
                    <div className = {styles.leadsubscribetext}>チャンネル登録、ぜひお願いします！</div>
                    <StaticImage src = "../../images/subscribe.png"
                    />
                </div>
            </a>
        </div>
    )
}

export default ChannelSubscribeLink;